import React, {useState} from "react"
import { StaticImage, GatsbyImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Banner from "../components/banner"
import Seo from "../components/seo"
import { useStaticQuery, graphql, withPrefix, Link } from "gatsby"
const banner_title  = ['Opportunities ', 3000, 'don’t happen, ', 2000, 'you create them. ',1500]



const Career = () => { 

return (
<Layout>
<Seo title="Explore career opportunities at Estore Company· Discover more about the Estore Company Good life company ·  " />
<Banner className = "service" title = {banner_title} sticky_first = "Careers" sticky_second  = "" />


<section className = "career_intro">

        <div className = "container">

                <div className = "img_row">

                    <div className = "car_img">

                    <StaticImage
                            src="../images/first_car.png"
                            quality = "100"
                          
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />
                    </div>

                    <div className = "car_img">

<StaticImage
        src="../images/second_car.png"
        quality = "100"
        
        alt="A Gatsby astronaut"
        placeholder="tracedSVG"
        />
</div>



                </div>


                <div className = "img_row">

<div className = "car_img">

<StaticImage
        src="../images/third_car.png"
        quality = "100"
       
        alt="A Gatsby astronaut"
        placeholder="tracedSVG"
        />
</div>

<div className = "car_img">

<StaticImage
src="../images/fourth_car.png"
quality = "100"

alt="A Gatsby astronaut"
placeholder="tracedSVG"
/>
</div>



</div>



<div className="cur_vac">

    <h1>current vacancies</h1>
    <p>we currently have following vacancies in our U.S.A & Pakistan offices. Feel free to email us your resume/CV at   
         <Link to ="mailto:career@estorecompany.com"> career@estorecompany.com</Link>  </p>
</div>


<section className="faqa career">




            <h1>KARACHI</h1>

            <div class="accordion-container">
 
  <div class="set">
    <a href="#">
   Director of Digital Marketing and inside Sales
      <i class="fa fa-plus"></i>
    </a>
    <div class="content">
    <ul>
                <li>Define the product strategy and roadmap</li>
                <li>Deliver MRDs and PRDs with prioritized features and corresponding justification</li>
                <li>Work with external third parties to assess partnerships and licensing opportunities</li>
                <li>Run beta and pilot programs with early-stage products and samples</li>
                <li>Be an expert with respect to the competition</li>
                <li>Act as a leader within the company</li>
                <li>Minimum of N years experience as a Product Manager</li>
                <li>Demonstrated success defining and launching excellent products</li>
                <li>Excellent written and verbal communication skills</li>



        </ul>
        <h4>Candidates matching the above mentioned requirements are encouraged 
            to apply at <a href ="mailto:career@estorecompany.com">career@estorecompany.com</a>   with the subject of the position.</h4>



     
    </div>
  </div>
  <div class="set">
    <a href="#">
   Senior Product Manager/Project Manager
      <i class="fa fa-plus"></i>
    </a>
    <div class="content">
    <ul>
                <li>Define the product strategy and roadmap</li>
                <li>Deliver MRDs and PRDs with prioritized features and corresponding justification</li>
                <li>Work with external third parties to assess partnerships and licensing opportunities</li>
                <li>Run beta and pilot programs with early-stage products and samples</li>
                <li>Be an expert with respect to the competition</li>
                <li>Act as a leader within the company</li>
                <li>Minimum of N years experience as a Product Manager</li>
                <li>Demonstrated success defining and launching excellent products</li>
                <li>Excellent written and verbal communication skills</li>



        </ul>
        <h4>Candidates matching the above mentioned requirements are encouraged 
            to apply at  <a href ="mailto:career@estorecompany.com">career@estorecompany.com</a>   with the subject of the position.</h4>



    </div>
  </div>
  <div class="set">
    <a href="#">
   Product Manager
      <i class="fa fa-plus"></i>
    </a>
    <div class="content">
    
        <ul>
                <li>Define the product strategy and roadmap</li>
                <li>Deliver MRDs and PRDs with prioritized features and corresponding justification</li>
                <li>Work with external third parties to assess partnerships and licensing opportunities</li>
                <li>Run beta and pilot programs with early-stage products and samples</li>
                <li>Be an expert with respect to the competition</li>
                <li>Act as a leader within the company</li>
                <li>Minimum of N years experience as a Product Manager</li>
                <li>Demonstrated success defining and launching excellent products</li>
                <li>Excellent written and verbal communication skills</li>



        </ul>
        <h4>Candidates matching the above mentioned requirements are encouraged 
            to apply at <a href ="mailto:career@estorecompany.com">career@estorecompany.com</a>  with the subject of the position.</h4>

    </div>
  </div>

 

  <div class="set">
    <a href="#">
 Senior React jS Develop
      <i class="fa fa-plus"></i>
    </a>
    <div class="content">
    
        <ul>
                <li>Define the product strategy and roadmap</li>
                <li>Deliver MRDs and PRDs with prioritized features and corresponding justification</li>
                <li>Work with external third parties to assess partnerships and licensing opportunities</li>
                <li>Run beta and pilot programs with early-stage products and samples</li>
                <li>Be an expert with respect to the competition</li>
                <li>Act as a leader within the company</li>
                <li>Minimum of N years experience as a Product Manager</li>
                <li>Demonstrated success defining and launching excellent products</li>
                <li>Excellent written and verbal communication skills</li>



        </ul>
        <h4>Candidates matching the above mentioned requirements are encouraged 
            to apply at <a href ="mailto:career@estorecompany.com">career@estorecompany.com</a>  with the subject of the position.</h4>

    </div>
  </div>



</div>



<h1>USA</h1>

<div class="accordion-container">

<div class="set">
<a href="#">
Director of Digital Marketing and inside Sales
<i class="fa fa-plus"></i>
</a>
<div class="content">
<ul>
    <li>Define the product strategy and roadmap</li>
    <li>Deliver MRDs and PRDs with prioritized features and corresponding justification</li>
    <li>Work with external third parties to assess partnerships and licensing opportunities</li>
    <li>Run beta and pilot programs with early-stage products and samples</li>
    <li>Be an expert with respect to the competition</li>
    <li>Act as a leader within the company</li>
    <li>Minimum of N years experience as a Product Manager</li>
    <li>Demonstrated success defining and launching excellent products</li>
    <li>Excellent written and verbal communication skills</li>



</ul>
<h4>Candidates matching the above mentioned requirements are encouraged 
to apply at <a href ="mailto:career@estorecompany.com">career@estorecompany.com</a>   with the subject of the position.</h4>




</div>
</div>
<div class="set">
<a href="#">
Senior Product Manager/Project Manager
<i class="fa fa-plus"></i>
</a>
<div class="content">
<ul>
    <li>Define the product strategy and roadmap</li>
    <li>Deliver MRDs and PRDs with prioritized features and corresponding justification</li>
    <li>Work with external third parties to assess partnerships and licensing opportunities</li>
    <li>Run beta and pilot programs with early-stage products and samples</li>
    <li>Be an expert with respect to the competition</li>
    <li>Act as a leader within the company</li>
    <li>Minimum of N years experience as a Product Manager</li>
    <li>Demonstrated success defining and launching excellent products</li>
    <li>Excellent written and verbal communication skills</li>



</ul>
<h4>Candidates matching the above mentioned requirements are encouraged 
to apply at  <a href ="mailto:career@estorecompany.com">career@estorecompany.com</a>   with the subject of the position.</h4>



</div>
</div>
<div class="set">
<a href="#">
Product Manager
<i class="fa fa-plus"></i>
</a>
<div class="content">

<ul>
    <li>Define the product strategy and roadmap</li>
    <li>Deliver MRDs and PRDs with prioritized features and corresponding justification</li>
    <li>Work with external third parties to assess partnerships and licensing opportunities</li>
    <li>Run beta and pilot programs with early-stage products and samples</li>
    <li>Be an expert with respect to the competition</li>
    <li>Act as a leader within the company</li>
    <li>Minimum of N years experience as a Product Manager</li>
    <li>Demonstrated success defining and launching excellent products</li>
    <li>Excellent written and verbal communication skills</li>



</ul>
<h4>Candidates matching the above mentioned requirements are encouraged 
to apply at <a href ="mailto:career@estorecompany.com">career@estorecompany.com</a>  with the subject of the position.</h4>

</div>
</div>



<div class="set">
<a href="#">
Senior React jS Develop
<i class="fa fa-plus"></i>
</a>
<div class="content">

<ul>
    <li>Define the product strategy and roadmap</li>
    <li>Deliver MRDs and PRDs with prioritized features and corresponding justification</li>
    <li>Work with external third parties to assess partnerships and licensing opportunities</li>
    <li>Run beta and pilot programs with early-stage products and samples</li>
    <li>Be an expert with respect to the competition</li>
    <li>Act as a leader within the company</li>
    <li>Minimum of N years experience as a Product Manager</li>
    <li>Demonstrated success defining and launching excellent products</li>
    <li>Excellent written and verbal communication skills</li>



</ul>
<h4>Candidates matching the above mentioned requirements are encouraged 
to apply at <a href ="mailto:career@estorecompany.com">career@estorecompany.com</a>  with the subject of the position.</h4>

</div>
</div>



</div>

       


</section>
         
        </div>

</section>



<section className="our_process">


        <div className="container">


            <h1>our Six step process.</h1>

            <p>we currently have following vacancies in our U.S.A & Pakistan offices. Feel free to email us your resume/CV at   
         <Link to ="mailto:career@estorecompany.com"> career@estorecompany.com</Link></p>

        <div className = "for_desk">
         <StaticImage
            src="../images/six_steps.png"
            quality = "100"
          layout ="fullWidth"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
</div>
            <div className = "for_mobile">

               <ul>
                       <li>
                       <StaticImage
            src="../images/mob_step.png"
            quality = "100"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
                       </li>

                       <li>
                       <StaticImage
            src="../images/mob_step_2.png"
            quality = "100"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
                       </li>
                       <li>
                       <StaticImage
            src="../images/mob_step_3.png"
            quality = "100"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
                       </li>

                       </ul>                 


            </div>


        </div>

</section>




</Layout>




)



}



export default Career